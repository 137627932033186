<template>
    <div class="app-container">
        <el-form :model="form" label-width="80px">
            <div style="margin-bottom: 20px;">
                <el-button type="primary" @click="handleAdd" icon="el-icon-plus">新增公司</el-button>
                <el-button @click="handleExport">导出列表</el-button>
            </div>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="name" align="center" label="公司名称" width="150px"></el-table-column>
                <el-table-column align="center" label="状态" width="150px">
                    <template slot-scope="scope">{{scope.row.status === 1 ? '正常' : '冻结'}}</template>
                </el-table-column>
                <el-table-column prop="key3" align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="handleSetting(scope.row.id)">架构设置</el-button>
                        <el-button type="primary" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="primary" v-if="scope.row.status === 1" @click="handleFreeze(scope.row)">冻结
                        </el-button>
                        <el-button type="success" v-else @click="handleFreeze(scope.row)">解冻</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
        <!-- 新增公司 -->
        <el-dialog title="新增公司" :visible.sync="addVisible" width="600px"
                   :close-on-click-modal="false">
            <el-form ref="addForm" :model="addForm" label-width="80px" :rules="rules">
                <el-form-item label="公司名称" prop="name">
                    <el-input v-model="addForm.name" placeholder="请输入公司名称" style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleAddSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改公司 -->
        <el-dialog title="修改公司" :visible.sync="editVisible" width="600px" :close-on-click-modal="false"
                   :before-close="handleEditClose">
            <el-form ref="editForm" :model="editForm" label-width="80px" :rules="rules">
                <el-form-item label="公司名称" prop="name">
                    <el-input v-model="editForm.name" placeholder="请输入公司名称" style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleEditClose">取 消</el-button>
                <el-button type="primary" @click="handleEditSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 架构设置 -->
        <el-dialog title="架构设置" :visible.sync="settingVisible" width="800px" :close-on-click-modal="false">
            <el-tree :data="settingData" node-key="id" default-expand-all :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ data.levelName }}</span>
                    <span style="margin-left: 30px;">
                        <el-button type="text" size="mini" @click="modifyLevel(data)">编辑</el-button>
                        <el-button type="text" size="mini" @click="addLevel(data.id)" v-if="data.level < 5">添加下一级</el-button>
                        <el-button type="text" size="mini" @click="addSameLevel(data.id)">添加同级</el-button>
                        <el-button type="text" size="mini" @click="deleteLevel(data.id)">删除</el-button>
                    </span>
                </span>
            </el-tree>
            <div slot="footer" class="dialog-footer">
                <el-button @click="settingVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
        <!-- 添加架构 -->
        <el-dialog title="添加下一级架构" :visible.sync="addSettingVisible" append-to-body width="600px"
                   :close-on-click-modal="false" :before-close="handleAddSettingClose">
            <el-form ref="addSettingForm" :model="addSettingForm" label-width="80px" :rules="settingRules">
                <el-form-item label="架构名称" prop="levelName">
                    <el-input v-model="addSettingForm.levelName" placeholder="请输入架构名称"
                              style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleAddSettingClose">取 消</el-button>
                <el-button type="primary" @click="handleAddSettingSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 添加同级架构 -->
        <el-dialog title="添加同级架构" :visible.sync="addSameVisible" append-to-body width="600px"
                   :close-on-click-modal="false" :before-close="handleAddSameClose">
            <el-form ref="addSameForm" :model="addSameForm" label-width="80px" :rules="settingRules">
                <el-form-item label="架构名称" prop="levelName">
                    <el-input v-model="addSameForm.levelName" placeholder="请输入架构名称"
                              style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleAddSameClose">取 消</el-button>
                <el-button type="primary" @click="handleAddSameSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改架构名称 -->
        <el-dialog title="修改架构名称" :visible.sync="editSettingVisible" append-to-body width="600px"
                   :close-on-click-modal="false" :before-close="handleEditSettingClose">
            <el-form ref="editSettingForm" :model="editSettingForm" label-width="80px" :rules="settingRules">
                <el-form-item label="架构名称" prop="levelName">
                    <el-input v-model="editSettingForm.levelName" placeholder="请输入架构名称"
                              style="width: 300px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleEditSettingClose">取 消</el-button>
                <el-button type="primary" @click="handleEditSettingSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getCompanyListApi,
        changeCompanyStatusApi,
        addCompanyApi,
        modifyCompanyApi,
        getCompanyTreeApi,
        addLevelApi,
        addSameLevelApi,
        modifyLevelNameApi,
        delLevelApi,
        exportCompanyListApi
    } from '@/api'

    export default {
        name: 'company',
        data() {
            return {
                tableData: [],
                addVisible: false,
                addForm: {
                    name: undefined
                },
                editVisible: false,
                editForm: {
                    id: undefined,
                    name: undefined
                },
                rules: {
                    name: [
                        {required: true, message: '请输入公司名称', trigger: 'blur'}
                    ]
                },
                settingVisible: false,
                settingData: [],
                addSettingVisible: false,
                addSettingForm: {
                    parentId: undefined,
                    levelName: undefined
                },
                editSettingVisible: false,
                editSettingForm: {
                    id: undefined,
                    levelName: undefined
                },
                settingRules: {
                    levelName: [
                        {required: true, message: '请输入架构名称', trigger: 'blur'}
                    ]
                },
                // 添加同级架构
                addSameVisible: false,
                addSameForm: {
                    id: undefined,
                    levelName: undefined
                },
                companyId: undefined
            }
        },
        mounted() {
            this.getCompanyList()
        },
        methods: {
            getCompanyList() {
                getCompanyListApi().then(res => {
                    if (res) {
                        this.tableData = res.data
                    }
                })
            },
            handleFreeze(row) {
                this.$confirm('确认' + (row.status === 1 ? '冻结?' : '解冻?'), '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    changeCompanyStatusApi({
                        id: row.id,
                        status: row.status === 1 ? 0 : 1
                    }).then(res => {
                        if (res) {
                            this.$message.success('操作成功!')
                            this.getCompanyList()
                        }
                    })
                })
            },
            handleAdd() {
                this.addVisible = true
                this.$refs['addForm'].resetFields()
            },
            handleEdit(row) {
                this.editForm['id'] = row.id
                this.editForm['name'] = row.name
                this.editVisible = true
            },
            handleEditClose() {
                this.$refs['editForm'].resetFields()
                this.editVisible = false
            },
            handleAddSubmit() {
                this.$refs['addForm'].validate(valid => {
                    if (valid) {
                        addCompanyApi(this.addForm).then(res => {
                            if (res) {
                                this.$message.success('添加成功!')
                                this.getCompanyList()
                                this.addVisible = false
                            }
                        })
                    }
                })
            },
            handleEditSubmit() {
                this.$refs['editForm'].validate(valid => {
                    if (valid) {
                        modifyCompanyApi(this.editForm).then(res => {
                            if (res) {
                                this.$message.success('修改成功!')
                                this.getCompanyList()
                                this.editVisible = false
                            }
                        })
                    }
                })
            },
            handleSetting(companyId) {
                if (companyId) {
                    this.companyId = companyId
                }
                getCompanyTreeApi({
                    companyId: this.companyId
                }).then(res => {
                    if (res) {
                        this.settingData = res.data
                        this.settingVisible = true
                    }
                })
            },
            modifyLevel(row) {
                this.editSettingForm['id'] = row.id
                this.editSettingForm['levelName'] = row.levelName
                this.editSettingVisible = true
            },
            handleEditSettingSubmit() {
                this.$refs['editSettingForm'].validate(valid => {
                    if (valid) {
                        modifyLevelNameApi(this.editSettingForm).then(res => {
                            if (res) {
                                this.$message.success('修改成功!')
                                this.handleSetting()
                                this.editSettingVisible = false
                            }
                        })
                    }
                })
            },
            handleEditSettingClose() {
                this.$refs['editSettingForm'].resetFields()
                this.editSettingVisible = false
            },
            addLevel(id) {
                this.addSettingForm['parentId'] = id
                this.addSettingVisible = true
                this.$refs['addSettingForm'].resetFields()
            },
            addSameLevel(id) {
                this.addSameForm['id'] = id
                this.addSameVisible = true
                this.$refs['addSameForm'].resetFields()
            },
            handleAddSettingSubmit() {
                this.$refs['addSettingForm'].validate(valid => {
                    if (valid) {
                        addLevelApi(this.addSettingForm).then(res => {
                            if (res) {
                                this.$message.success('添加成功!')
                                this.handleSetting()
                                this.addSettingVisible = false
                            }
                        })
                    }
                })
            },
            handleAddSettingClose() {
                this.$refs['addSettingForm'].resetFields()
                this.addSettingVisible = false
            },
            handleAddSameSubmit() {
                this.$refs['addSameForm'].validate(valid => {
                    if (valid) {
                        addSameLevelApi(this.addSameForm).then(res => {
                            if (res) {
                                this.$message.success('添加成功!')
                                this.handleSetting()
                                this.addSameVisible = false
                            }
                        })
                    }
                })
            },
            handleAddSameClose() {
                this.$refs['addSameForm'].resetFields()
                this.addSameVisible = false
            },
            deleteLevel(id) {
                this.$confirm('确认删除该架构?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delLevelApi({id}).then(res => {
                        if (res) {
                            this.$message.success('删除成功!')
                            this.handleSetting()
                        }
                    })
                })
            },
            handleExport() {
                exportCompanyListApi().then(res => {
                    if (res) {
                        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = '公司列表数据导出'
                        link.click()
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
